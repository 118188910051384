import client from "@/api/client";

const api = {
  // 通过运单号查询运单信息
  searchTransportTrack: "/api/openapi/WarehouseService/LogisticsTrack/SearchTransportTrack",
}

export const searchTransportTrack = (params) => {
  return client.request({
    url: api.searchTransportTrack,
    params,
    method: "GET",
  });
};