<template>
  <div class="search">
    <img src="@/assets/image/icon/icon-search.svg" alt="" />
    <slot name="text">
      <span v-if="text">{{ text }}</span>
      <span v-else>{{ $t('public.search_tracking_text') }}</span>
    </slot>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    text: {
      type: String
    }
  }
});
</script>

<style lang="less" scoped>
.search {
  padding: 50px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  img {
    width: 100px;
  }
  span {
    margin-top: 20px;
    color: #8e8e8e;
  }
}
</style>